<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">实时数据捕获</div>
        <div class="en">DU-RDC</div>
        <p>采用一站式体验、开箱即用、集群负载均衡、</p>
        <p>高可用等行业领先理念进行设计</p>
      </div>
    </div>
    <div class="main-box w-1200">
			<div class="product-position-box">
				<div class="title">产品市场定位</div>
				<div class="sub-title">
					<p>为支持国内信创事业的发展，公司自主研发了实时数据捕获产品</p>
					<p>支撑起从Oracle、Mysql等数据库同步到大数据、数据湖、实时数仓等数据平台</p>
				</div>
				<div class="item-box">
					<div class="text">
						<p>对标国际</p>
						<p>同类产品</p>
					</div>
					<img src="~@/assets/images/product/position-icon-1.jpg"/>
				</div>
				<div class="item-box">
					<div class="text">
						<p>行业领先</p>
						<p>信创标准</p>
					</div>
					<img src="~@/assets/images/product/position-icon-2.jpg"/>
				</div>
				<div class="item-box">
					<div class="text">
						<p>贴合国内</p>
						<p>用户习惯</p>
					</div>
					<img src="~@/assets/images/product/position-icon-3.jpg"/>
				</div>
				<div class="item-box">
					<div class="text">
						<p>价格实惠</p>
						<p>使用便捷</p>
					</div>
					<img src="~@/assets/images/product/position-icon-4.jpg"/>
				</div>
			</div>
			<div class="main-item">
				<div class="center-title">
					赋能行业
				</div>
				<div class="industry-box">
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-1.png"/>
						<p>银行</p>
					</div>
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-2.png"/>
						<p>证券</p>
					</div>
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-3.png"/>
						<p>互金</p>
					</div>
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-4.png"/>
						<p>制造</p>
					</div>
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-5.png"/>
						<p>政府</p>
					</div>
					<div class="item">
						<img src="~@/assets/images/product/industry-icon-6.png"/>
						<p>交通</p>
					</div>
				</div>
			</div>
			<div class="main-item">
				<div class="center-title">
					设计理念
				</div>
				<div class="idea-box">
					<div class="item">
						<div class="text">
							<p>技术架构</p>
							<p>产品使用主流B/S技术架构，按一站式体验、开箱即用、集群负载均衡、高可用等行业领先理念进行设计。</p>
						</div>
						<img src="~@/assets/images/product/idea-icon-1.png"/>
					</div>
					<div class="item">
						<div class="text">
							<p>日志挖掘技术</p>
							<p>通过日志挖掘技术，降低对业务系统的影响，从Mysql、Oracle等从业务系统数据库增/全量实时同步数据，输出到大数据平台、数据湖、实时数据仓库等目标数据库。</p>
						</div>
						<img src="~@/assets/images/product/idea-icon-2.png"/>
					</div>
				</div>
			</div>
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>数据源管理</label>
              <p>包含数据源的基本信息，数据源的管理；以及实时数据同步数据源级别的配置与测试。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>实时采集任务管理</label>
              <p>包括表级采集任务的新增、修改、删除等基础功能，任务的批量导出导入功能，元数据变更提醒与确认，以及任务启停等运维管理功能。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>数据捕获日志</label>
              <p>以数据源视角，查询数据捕获的运行进度。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>任务执行日志</label>
              <p>以任务视角，查询任务的执行情况。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>系统运行日志</label>
              <p>在线查看系统运行日志。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/rdc-architecture.png"/>
        </div>
      </div>
			<div class="main-item">
				<div class="title">技术特点</div>
				<div class="content">
					<div class="core-box">
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-1.png"/>
							<div class="text-box">
								<label>实时捕获</label>
								<p>实时数据捕获同步产品，通过日志挖掘技术，实时获取数据库记录，对业务系统的影响较低</p>
							</div>
						</div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-2.png"/>
							<div class="text-box">
								<label>产品支持线性扩展</label>
								<p>产品基于SpringBoot，可根据需要快速部署到任意数量的节点上，对操作系统，硬件无特殊要求，也不需要统一</p>
							</div>
						</div>
					</div>
				</div>
			</div>
      <div class="main-item">
        <div class="title">产品优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>元数据同步</label>
                <p>产品具备自动同步业务系统数据库元数据，目标数据库可自动建表，可随着元数据变更自动变更</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>断点机制</label>
                <p>支持异常自动处理与监测点机制</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>多数据库支持</label>
                <p>支持输出到多种数据库，如Oracle、Mysql、Kafka、Iceberg等</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>增全量衔接</label>
                <p>采集任务可先进行全量数据的同步，再进行增量数据采集与同步，达到无缝衔接</p>
              </div>
            </div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-1.png"/>
							<div class="text-box">
								<label>支持大表采集</label>
								<p>支持采集字段数较多的表，也可根据需要同步表里的部分字段降低系统负荷</p>
							</div>
						</div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-2.png"/>
							<div class="text-box">
								<label>支持大字段采集</label>
								<p>支持数据库中的大字段的采集与同步</p>
							</div>
						</div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/product/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/product/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/product/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
	.center-title {
		text-align: center;
		font-size: 40px;
		color: #333;
		padding-top: 45px
	}
	.industry-box {
		padding-top: 50px;
		overflow: hidden;
		.item {
			float: left;
			width: 200px;
			text-align: center;
			p {
				color: #1a2983;
				font-size: 24px;
				margin-top: 22px;
			}
			img {
				width: 94px;
			}
		}
	}
	.idea-box {
		margin-top: 50px;
		overflow: hidden;
		.item {
			background-color: #fff;
			border-radius: 5px;
			float: left;
			width: 590px;
			.text {
				float: left;
				p {
					padding-left: 30px;
					color: #1a2983;
					width: 258px;
					line-height: 1.3;
					&:first-child {
						padding-top: 30px;
						font-size: 28px;
						margin-bottom: 20px;
					}
				}
			}
			&:not(:last-child){
				margin-right: 19px;
			}	
			img {
				width: 240px;
				padding-left: 30px;
			}
		}
	}
  .content {
    img {
      width: 1199px;
    }
  }
	.product-position-box {
		padding-top: 10px;
		overflow: hidden;
		.title {
			text-align: center;
			font-size: 40px;
			color: #333;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				top: 120px;
				left: 530px;
				width: 140px;
				height: 5px;
				border-radius: 2px;
				background-color: #1a2983;
			}
		}
		.sub-title {
			text-align: center;
			margin-top: 34px;
			line-height: 1.7;
			color: #999;
		}
		.item-box {
			border-radius: 5px;
			float: left;
			width: 285px;
			background-color: #fff;
			overflow: hidden;
			margin-top: 28px;
			&:not(:last-child) {
				margin-right: 19px;
			}
			.text {
				float: left;
				font-size: 24px;
				line-height: 1.5;
				color: #1a2983;
				padding-top: 40px;
				padding-left: 30px;
			}
			img {
				padding-left: 22px;
				padding-top: 20px;
				padding-bottom: 20px;
				width: 124px;
			}
		}
	}
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 387px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+3) {
        margin-bottom: 19px;
      }
			&:nth-child(4), &:nth-child(5) {
				width: 590px;
				height: 190px;
			}
      &:nth-child(3), &:nth-child(5){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
